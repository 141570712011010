import { rDate } from '@/utils'

export const formatValue = function(item, val) {
  if (val === null) return val

  const date = val ? new Date(val) : null

  const type = item.datatype || item.type

  switch (type) {
    case 'datetime':
    case 'time':
    case 'timestamptz':
      return date?.toLocaleString('ru-RU') || date
    case 'date':
      return date ? rDate.format(date, 'DD.MM.YYYY') : ''
    case 'number':
    case 'integer':
    case 'numeric':
    case 'float':
    case 'decimal':
      return val?.toLocaleString('ru-RU') || val
    case 'jsonb':
      if (val && Array.isArray(val)) {
        if (typeof val?.[0] === 'object') {
          return val?.map(v => v?.name || 'нет значения')?.join(', ')
        } else {
          return val && Array.isArray(val) ? val?.join(', ') : ''
        }
      }
      return ''
    default:
      return val
  }
}

export const arrayMoveByIndex = (arr, from, to) => {
  const elem = arr[from]

  arr.splice(from, 1)
  arr.splice(to, 0, elem)
}
